import "normalize.css/normalize.css"
import 'semantic-ui-css/semantic.min.css'
import "./src/styles/main.scss"

import { anchorate } from 'anchorate'


export function onRouteUpdate(){
  return anchorate()
}
